import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Hero from '../components/hero'
import Features from '../components/features'

export default function Home() {

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 500,
      easing: 'ease-out-cubic',
    })
  })

  return (
    <div>
      <main className="grow">
        <Hero />
        <Features />
      </main>
    </div>
  )
}
