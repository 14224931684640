import HeroThumb from '@/public/images/server-room-generator-comp.jpg'
import Image from 'next/image'

export default function Hero() {
  return (
    <section className="relative">

      {/* Illustration behind hero content */}
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          {/* <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="64" r="64" />
            <circle cx="155" cy="443" r="64" />
          </g> */}
        </svg>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-30 md:pb-20">
          {/* Section header */}
          <div className="text-center pb-12 md:pb-16">
            <h1 className="text-3xl md:text-4xl font-extrabold leading-tighter tracking-tighter mb-4 text-white" data-aos="zoom-y-out">Get <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-teal-500">onchain</span></h1>
            <div className="max-w-3xl mx-auto">
              <p className="text-xl text-gray-200 mb-8" data-aos="zoom-y-out" data-aos-delay="150">One click minting on Cardano</p>
            </div>
          </div>
          
          {/* Hero image */}
          <div className="relative flex justify-center mb-8" data-aos="zoom-y-out" data-aos-delay="450">
            <Image src={HeroThumb} width={620} alt="hero"></Image>
          </div>
        </div>
      </div>
    </section>
  )
}